import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  groupsSelector,
  isGroupCreatingSelector,
  isGroupDeletingSelector,
  isGroupUpdatingSelector,
  organizationSelector
} from "modules/enterprises/selectors";
import { Group, ROLES } from "modules/enterprises/types";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { validateName } from "utils/validateName";
import { string } from "yup";
import {
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  REGEX,
  ROUTES
} from "../../constants";
import { DIALOG_TYPES } from "./types";

const POLL_ID_PREFIX = "GROUPS";

const POLL_IDS = {
  groups: "GROUPS"
};

const title = "Groups";

const tableColumns: TableColumn<Group>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" }
];

export const Groups: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const groups = useSelector(groupsSelector);
  const isGroupCreating = useSelector(isGroupCreatingSelector);
  const isGroupUpdating = useSelector(isGroupUpdatingSelector);
  const isGroupDeleting = useSelector(isGroupDeletingSelector);
  const isOperationInProgress =
    isGroupDeleting || isGroupCreating || isGroupUpdating;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const isUserAdmin = [ROLES.OWNER, ROLES.ADMIN].includes(
    organization?.role as ROLES
  );

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Groups",
      url: generatePath(ROUTES.GROUPS, {
        organizationId: matchParams.organizationId
      })
    }
  ];

  const generateTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.GROUP, {
        organizationId: matchParams.organizationId,
        groupId: id
      }),
    [matchParams.organizationId]
  );

  const handleConfirmCreateGroup = useCallback(
    (data: { name: string }) => {
      dispatch(
        enterprisesActions.createGroup.started({
          organizationId: matchParams.organizationId!,
          data
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.organizationId, handleCloseDialog]
  );

  const handleConfirmEditGroup = useCallback(
    (data: { name: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.updateGroup.started({
            organizationId: matchParams.organizationId!,
            id: selectedItemId,
            data
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, matchParams.organizationId, handleCloseDialog]
  );

  const handleConfirmDeleteGroup = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.deleteGroup.started({
          organizationId: matchParams.organizationId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [selectedItemId, dispatch, matchParams.organizationId, handleCloseDialog]);

  const handleConfirmLeaveGroup = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.leaveGroup.started({
          organizationId: matchParams.organizationId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [selectedItemId, dispatch, matchParams.organizationId, handleCloseDialog]);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.groups}`,
        action: enterprisesActions.getGroups.started({
          organizationId: matchParams.organizationId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(
        enterprisesActions.getGroups.started({
          organizationId: matchParams.organizationId!
        })
      );
    }
  }, [
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    matchParams.organizationId
  ]);

  const handleCreateGroupButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE,
      isOpened: true
    });
  }, []);

  const handleEditGroupMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleDeleteGroupMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const handleLeaveGroupMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.LEAVE,
      isOpened: true
    });
  }, []);

  const tableActions: TableRowActionsMenuItem<Group>[] = [
    {
      label: "Edit",
      handler: handleEditGroupMenuItemClick,
      isDisabled: () => !isUserAdmin
    },
    {
      label: "Delete",
      handler: handleDeleteGroupMenuItemClick,
      isDisabled: () => !isUserAdmin
    },
    {
      label: "Leave",
      handler: handleLeaveGroupMenuItemClick,
      isDisabled: () => isUserAdmin
    }
  ];

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingGroupName = groups?.find((group) => group.id === deletingItemId)
    ?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateGroup,
      title: "Create group",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.GROUP_NAME, ERROR_MESSAGES.GROUP_NAME)
        }
      ]
    },
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditGroup,
      title: "Edit group",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            groups?.find((group) => group.id === selectedItemId)?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.GROUP_NAME, ERROR_MESSAGES.GROUP_NAME)
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteGroup,
      title: `Are you sure you want to delete "${
        deletingGroupName ?? "selected"
      }" group?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.LEAVE]: {
      onConfirm: handleConfirmLeaveGroup,
      title: "Are you sure you want to leave selected group?",
      confirmButtonLabel: "Leave"
    }
  };

  return (
    <>
      <Head title={title} />
      {organization && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={groups || []}
        columns={tableColumns}
        actions={tableActions}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!groups}
        toolbarItems={
          isUserAdmin && (
            <Button
              onClick={handleCreateGroupButtonClick}
              variant={"contained"}
            >
              Create group
            </Button>
          )
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        onCancel={handleCloseDialog}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
