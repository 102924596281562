import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { KeyPairDialog } from "components/common/KeyPairDialog";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as clustersActions from "modules/clusters/actions";
import {
  clusterTemplatesSelector,
  isClusterCreatingSelector,
  isClusterDeletingSelector,
  isClusterResizingSelector,
  tableClustersSelector
} from "modules/clusters/selectors";
import { TableCluster } from "modules/clusters/types";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import { selectOptionFlavorsSelector } from "modules/instances/selectors";
import { FLAVOR_TYPE } from "modules/instances/types";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import * as securityActions from "modules/security/actions";
import {
  isKeyPairCreatingSelector,
  keyPairSelector,
  keyPairsSelector
} from "modules/security/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { isString } from "typeGuards/isString";
import { downloadFile } from "utils/downloadFile";
import { filterVersions } from "utils/filterVersions";
import { getSelectOption } from "utils/getSelectOption";
import { validateName } from "utils/validateName";
import { NumberSchema, number, ref, string } from "yup";
import { API_PATH } from "../../axios";
import {
  DEFAULT_FLAVOR,
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  MAX_NODE_COUNT,
  MIN_NODE_COUNT,
  REGEX,
  ROUTES,
  VOLUME_SIZE_STEP
} from "../../constants";
import { DIALOG_TYPES } from "./types";

const MIN_DOCKER_VOLUME_SIZE = 50;
const MIN_MASTER_COUNT = 1;
const DEFAULT_CLUSTER_AUTO_SCALING = true;

const POLL_ID_PREFIX = "CLUSTERS";

const POLL_IDS = {
  clusters: "CLUSTERS",
  flavors: "FLAVORS",
  clusterTemplates: "CLUSTER_TEMPLATES",
  keyPairs: "KEY_PAIRS"
};

const tableColumns: TableColumn<TableCluster>[] = [
  { key: "name", label: "Name" },
  { key: "status", label: "Status" },
  { key: "kubeTag", label: "Kubernetes version" },
  { key: "masterCount", label: "Master count" },
  { key: "nodeCount", label: "Node count" },
  { key: "totalvCPUs", label: "Total vCPUs" },
  {
    key: "totalMemory",
    label: "Total memory",
    sortingType: TABLE_SORTING_TYPES.NUMBER
  }
];

const title = "Clusters";

export const Clusters: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    projectId: string;
    regionId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const flavors = useSelector(selectOptionFlavorsSelector);
  const default_flavor = flavors?.find((flavor) =>
    flavor.name.includes(DEFAULT_FLAVOR)
  );
  const keyPairs = useSelector(keyPairsSelector);
  const clusterTemplates = useSelector(clusterTemplatesSelector);
  const clusters = useSelector(tableClustersSelector);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const isClusterCreating = useSelector(isClusterCreatingSelector);
  const isClusterResizing = useSelector(isClusterResizingSelector);
  const isClusterDeleting = useSelector(isClusterDeletingSelector);
  const isOperationInProgress =
    isClusterCreating || isClusterResizing || isClusterDeleting;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);
  const keyPair = useSelector(keyPairSelector);
  const previousKeyPair = usePrevious(keyPair);
  const isKeyPairCreating = useSelector(isKeyPairCreatingSelector);
  const isPreviousKeyPairCreating = usePrevious(isKeyPairCreating);
  const [isKeyPairDialogOpened, setIsKeyPairDialogOpened] = useState(false);
  const [isCreateKeyPairDialogOpened, setIsCreateKeyPairDialogOpened] =
    useState(false);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Clusters",
      url: generatePath(ROUTES.CLUSTERS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  const generateTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.CLUSTER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        clusterId: id
      }),
    [matchParams.projectId, matchParams.organizationId, matchParams.regionId]
  );

  const handleCreateClusterButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE,
      isOpened: true
    });
  }, []);

  const handleEditClusterMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleUpgradeClusterMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.UPGRADE,
      isOpened: true
    });
  }, []);

  const handleDeleteClusterMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const handleDownloadKubeconfigFileMenuItemClick = useCallback(
    (id: string) => {
      downloadFile(
        `${API_PATH}gotham-${matchParams.regionId}-kubernetes/method/${matchParams.projectId}/clusters/${id}/kubeconfig`
      );
      dispatch(
        notificationsActions.showNotification({
          title: "Kubeconfig file is generating. Download will start soon.",
          type: NOTIFICATION_TYPES.INFO
        })
      );
    },
    [matchParams.regionId, matchParams.projectId, dispatch]
  );

  const tableActions: TableRowActionsMenuItem<TableCluster>[] = [
    {
      label: "Download kubeconfig file",
      handler: handleDownloadKubeconfigFileMenuItemClick
    },
    {
      label: "Edit",
      handler: handleEditClusterMenuItemClick
    },
    {
      label: "Upgrade",
      handler: handleUpgradeClusterMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteClusterMenuItemClick
    }
  ];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.clusters}`,
        action: clustersActions.getClusters.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.flavors}`,
        action: instancesActions.getFlavors.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          type: FLAVOR_TYPE.CLUSTER
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.clusterTemplates}`,
        action: clustersActions.getClusterTemplates.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.keyPairs}`,
        action: securityActions.getKeyPairs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(clustersActions.clear());
    dispatch(instancesActions.clear());
    dispatch(securityActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(
        clustersActions.getClusters.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (isPreviousKeyPairCreating && !isKeyPairCreating) {
      dispatch(
        securityActions.getKeyPairs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    isPreviousKeyPairCreating,
    isKeyPairCreating,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const handleConfirmCreateCluster = useCallback(
    (data: {
      cluster_template_id: SelectOption;
      docker_volume_size: string;
      flavor_id: SelectOption;
      keypair: SelectOption;
      master_count: string;
      master_flavor_id: SelectOption;
      name: string;
      node_count: string;
      floating_ip_enabled: boolean;
      auto_scaling_enabled: boolean;
      min_node_count?: string;
      max_node_count?: string;
    }) => {
      dispatch(
        clustersActions.createCluster.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            name: data.name,
            cluster_template_id: data.cluster_template_id.value,
            docker_volume_size: Number(data.docker_volume_size),
            flavor_id: data.flavor_id.value,
            keypair: data.keypair.value,
            master_count: Number(data.master_count),
            master_flavor_id: data.master_flavor_id.value,
            node_count: Number(data.node_count),
            floating_ip_enabled: !data.floating_ip_enabled,
            auto_scaling_enabled: data.auto_scaling_enabled,
            ...(data.min_node_count
              ? { min_node_count: Number(data.min_node_count) }
              : {}),
            ...(data.max_node_count
              ? { max_node_count: Number(data.max_node_count) }
              : {})
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmEditCluster = useCallback(
    (data: { value: string }) => {
      if (selectedItemId) {
        dispatch(
          clustersActions.resizeCluster.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              node_count: Number(data.value)
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmUpgradeCluster = useCallback(
    (data: { cluster_template: SelectOption }) => {
      if (selectedItemId) {
        dispatch(
          clustersActions.upgradeCluster.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              cluster_template: data.cluster_template.value
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteCluster = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        clustersActions.deleteCluster.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleCreateKeyPair = useCallback(() => {
    setIsCreateKeyPairDialogOpened(true);
  }, []);

  const handleCloseCreateKeyPairDialog = useCallback(() => {
    setIsCreateKeyPairDialogOpened(false);
  }, []);

  const handleConfirmCreateKeyPair = useCallback(
    (data: { name: string }) => {
      dispatch(
        securityActions.createKeyPair.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data
        })
      );
      handleCloseCreateKeyPairDialog();
    },
    [
      dispatch,
      handleCloseCreateKeyPairDialog,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleCloseKeyPairDialog = useCallback(() => {
    setIsKeyPairDialogOpened(false);
  }, []);

  useEffect(() => {
    if (previousKeyPair !== keyPair && keyPair) {
      setIsKeyPairDialogOpened(true);
    }
  }, [
    previousKeyPair,
    keyPair,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingClusterName = clusters?.find(
    (cluster) => cluster.id === deletingItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateCluster,
      title: "Create cluster",
      confirmButtonLabel: "Create",
      yupNoSortEdges: [["min_node_count", "max_node_count"]],
      isLocked: isKeyPairCreating,
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .matches(
              REGEX.NAME_STARTS_WITH_LETTER,
              ERROR_MESSAGES.NAME_STARTS_WITH_LETTER
            )
            .matches(REGEX.CLUSTER_NAME, ERROR_MESSAGES.CLUSTER_NAME)
        },
        {
          name: "cluster_template_id",
          type: FIELD_TYPES.SELECT,
          label: "Cluster template",
          options: clusterTemplates?.map((clusterTemplate) =>
            getSelectOption(clusterTemplate, "name", "uuid")
          ),
          defaultValue: () => {
            return clusterTemplates
              ? getSelectOption(
                  clusterTemplates[clusterTemplates.length - 1],
                  "name",
                  "uuid"
                )
              : null;
          },
          rules: selectOptionSchema
        },
        {
          name: "master_flavor_id",
          type: FIELD_TYPES.SELECT,
          label: "Master flavor",
          options: flavors?.map((flavor) =>
            getSelectOption(flavor, "name", "id")
          ),
          defaultValue: () =>
            default_flavor
              ? getSelectOption(default_flavor, "name", "id")
              : null,
          rules: selectOptionSchema
        },
        {
          name: "flavor_id",
          type: FIELD_TYPES.SELECT,
          label: "Node flavor",
          options: flavors?.map((flavor) =>
            getSelectOption(flavor, "name", "id")
          ),
          defaultValue: () =>
            default_flavor
              ? getSelectOption(default_flavor, "name", "id")
              : null,
          rules: selectOptionSchema
        },
        {
          name: "keypair",
          type: FIELD_TYPES.SELECT,
          label: "Key pair",
          onCreate: handleCreateKeyPair,
          createText: "Create new key pair",
          options: keyPairs?.map((keyPair) =>
            getSelectOption(keyPair, "name", "id")
          ),
          defaultValue:
            keyPairs?.length === 1
              ? getSelectOption(keyPairs[0], "name", "id")
              : undefined,
          rules: selectOptionSchema
        },
        {
          name: "docker_volume_size",
          type: FIELD_TYPES.NUMBER,
          defaultValue: MIN_DOCKER_VOLUME_SIZE,
          label: "Docker image size",
          suffix: "GiB",
          step: VOLUME_SIZE_STEP,
          min: MIN_DOCKER_VOLUME_SIZE,
          rules: number()
            .integer()
            .min(
              MIN_DOCKER_VOLUME_SIZE,
              `Docker image size must be greater than or equals to ${MIN_DOCKER_VOLUME_SIZE} GiB.`
            )
            .test(
              "isMultipleOf",
              `Docker image size must be multiple of ${VOLUME_SIZE_STEP}.`,
              (value) => Boolean(value && value % VOLUME_SIZE_STEP === 0)
            )
            .required()
        },
        {
          name: "master_count",
          type: FIELD_TYPES.NUMBER,
          defaultValue: MIN_MASTER_COUNT,
          label: "Master count",
          min: MIN_MASTER_COUNT,
          rules: number()
            .integer()
            .min(
              MIN_MASTER_COUNT,
              `Master count must be greater than or equals to ${MIN_MASTER_COUNT}.`
            )
            .required()
        },
        {
          name: "node_count",
          type: FIELD_TYPES.NUMBER,
          defaultValue: MIN_NODE_COUNT,
          label: "Node count",
          min: MIN_NODE_COUNT,
          rules: number()
            .integer()
            .min(
              MIN_NODE_COUNT,
              `Node count must be greater than or equals to ${MIN_NODE_COUNT}.`
            )
            .required()
        },
        {
          name: "auto_scaling_enabled",
          type: FIELD_TYPES.CHECKBOX,
          label: "Enable auto-scaling",
          defaultValue: DEFAULT_CLUSTER_AUTO_SCALING
        },
        {
          name: "min_node_count",
          type: FIELD_TYPES.NUMBER,
          min: MIN_NODE_COUNT,
          max: MAX_NODE_COUNT,
          defaultValue: MIN_NODE_COUNT,
          rules: number()
            .integer()
            .min(
              MIN_NODE_COUNT,
              `Node count must be greater than or equals to ${MIN_NODE_COUNT}.`
            )
            .when(
              "max_node_count",
              (maxNodeCount: number[], schema: NumberSchema) => {
                return Number.isInteger(maxNodeCount[0])
                  ? schema.max(
                      ref("max_node_count"),
                      `Node count must be less than ${maxNodeCount[0]}.`
                    )
                  : schema.max(
                      MAX_NODE_COUNT,
                      `Node count must be less than ${MAX_NODE_COUNT}.`
                    );
              }
            ),
          isHidden: (fieldValues) => !fieldValues.auto_scaling_enabled,
          label: "Minimum node count"
        },
        {
          name: "max_node_count",
          type: FIELD_TYPES.NUMBER,
          max: MAX_NODE_COUNT,
          min: MIN_NODE_COUNT,
          defaultValue: MAX_NODE_COUNT,
          rules: number()
            .integer()
            .when(
              "min_node_count",
              (minNodeCount: number[], schema: NumberSchema) => {
                return Number.isInteger(minNodeCount[0]) &&
                  minNodeCount[0] <= MAX_NODE_COUNT
                  ? schema.min(
                      ref("min_node_count"),
                      `Node count must be greater than or equals to ${minNodeCount[0]}.`
                    )
                  : schema.min(
                      MIN_NODE_COUNT,
                      `Node count must be greater than or equals to ${MIN_NODE_COUNT}.`
                    );
              }
            )
            .max(
              MAX_NODE_COUNT,
              `Node count must be less than ${MAX_NODE_COUNT}.`
            ),
          label: "Maximum node count",
          isHidden: (fieldValues) => !fieldValues.auto_scaling_enabled
        },
        {
          name: "floating_ip_enabled",
          type: FIELD_TYPES.CHECKBOX,
          label: "Accessible on private network only"
        }
      ]
    },
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditCluster,
      title: "Edit cluster",
      confirmButtonLabel: "Save",
      isLocked: false,
      fields: [
        {
          name: "value",
          type: FIELD_TYPES.NUMBER,
          defaultValue: clusters?.find(
            (cluster) => cluster.id === selectedItemId
          )
            ? clusters?.find((cluster) => cluster.id === selectedItemId)
                ?.nodeCount
            : MIN_NODE_COUNT,
          label: "Node count",
          min: MIN_NODE_COUNT,
          rules: number()
            .integer()
            .min(
              MIN_NODE_COUNT,
              `Node count must be greater than or equals to ${MIN_NODE_COUNT}.`
            )
            .required()
        }
      ]
    },
    [DIALOG_TYPES.UPGRADE]: {
      onConfirm: handleConfirmUpgradeCluster,
      title: "Upgrade cluster",
      confirmButtonLabel: "Upgrade",
      isLocked: false,
      fields: [
        {
          name: "cluster_template",
          type: FIELD_TYPES.SELECT,
          label: "Cluster template",
          helperText:
            "✍ You can choose the current version to re-apply the configuration.",
          options: () => {
            const currentTemplateName = clusters?.find(
              (cluster) => cluster.id === selectedItemId
            )?.kubeTag;
            return currentTemplateName
              ? filterVersions(
                  currentTemplateName,
                  clusterTemplates,
                  (obj) => obj.name
                ).map(({ name, uuid }) => ({
                  label:
                    name === currentTemplateName ? `${name}  (current)` : name,
                  value: uuid
                }))
              : null;
          },
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      isLocked: false,
      onConfirm: handleConfirmDeleteCluster,
      title: `Are you sure you want to delete "${
        deletingClusterName ?? "selected"
      }" cluster?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the cluster name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Cluster name does not match",
              test: function (value) {
                return value === deletingClusterName;
              }
            })
        }
      ],
      confirmButtonLabel: "Delete"
    }
  };

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={clusters || []}
        columns={tableColumns}
        actions={tableActions}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!clusters}
        toolbarItems={
          <Button
            disabled={!keyPairs || !clusterTemplates || !flavors}
            onClick={handleCreateClusterButtonClick}
            variant={"contained"}
          >
            Create cluster
          </Button>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        isLocked={dialogProps[dialog.type].isLocked}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
        yupNoSortEdges={dialogProps[dialog.type].yupNoSortEdges}
      />
      <FormDialog
        isOpened={isCreateKeyPairDialogOpened}
        onCancel={handleCloseCreateKeyPairDialog}
        fields={[
          {
            name: "name",
            type: FIELD_TYPES.TEXT,
            label: "Name",
            rules: string()
              .required()
              .test({
                name: "validateName",
                test: validateName(ENTITY_NAME_LENGTH)
              })
              .test(
                "sameNameCheckFormat",
                "This name already exists",
                (value) => {
                  const name = keyPairs?.find((keypair) => keypair.id === value)
                    ?.name;
                  return Boolean(value && !name);
                }
              )
          }
        ]}
        onConfirm={handleConfirmCreateKeyPair}
        title={"Create new SSH key"}
        confirmButtonLabel={"Create"}
      />
      {keyPair && isString(keyPair.private_key) && (
        <KeyPairDialog
          isOpened={isKeyPairDialogOpened}
          name={keyPair.name}
          privateKey={keyPair.private_key}
          onClose={handleCloseKeyPairDialog}
        />
      )}
    </>
  );
};
