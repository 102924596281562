import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LeaveIcon from "@mui/icons-material/ExitToApp";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  groupSelector,
  isGroupDeletingSelector,
  isGroupInvitationDeletingSelector,
  isGroupLeavingSelector,
  isGroupUpdatingSelector,
  isUserInvitingToGroupSelector,
  isUserRemovingFromGroupSelector,
  organizationSelector,
  tableGroupUsersSelector
} from "modules/enterprises/selectors";
import { ROLES, TableUser } from "modules/enterprises/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import {
  isGroupFromProjectRemovingSelector,
  isGroupToProjectAssigningSelector,
  organizationProjectsSelector
} from "modules/projects/selectors";
import { TableProject } from "modules/projects/types";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getParentPath } from "utils/getParentPath";
import { getProjectRegion } from "utils/getProjectRegion";
import { getSelectOption } from "utils/getSelectOption";
import { validateName } from "utils/validateName";
import { string } from "yup";
import {
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  REGEX,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES } from "./types";

const POLL_ID_PREFIX = "GROUP";

const POLL_IDS = {
  group: "GROUP",
  groupUsers: "GROUP_USERS",
  groupInvitations: "GROUP_INVITATIONS",
  organizationProjects: "ORGANIZATION_PROJECTS"
};

const userTableColumns: TableColumn<TableUser>[] = [
  { key: "fullName", label: "Full name" },
  { key: "id", label: "ID" },
  { key: "formattedEmail", label: "E-mail" }
];

const projectTableColumns: TableColumn<TableProject>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "region", label: "Region" }
];

export const Group: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    groupId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const group = useSelector(groupSelector);
  const users = useSelector(tableGroupUsersSelector);
  const isUserInviting = useSelector(isUserInvitingToGroupSelector);
  const isInvitationDeleting = useSelector(isGroupInvitationDeletingSelector);
  const isInvitationOperationInProgress =
    isUserInviting || isInvitationDeleting;
  const previousIsInvitationOperationInProgress = usePrevious(
    isInvitationOperationInProgress
  );
  const isUserRemoving = useSelector(isUserRemovingFromGroupSelector);
  const organizationProjects = useSelector(organizationProjectsSelector);
  const isGroupUpdating = useSelector(isGroupUpdatingSelector);
  const isGroupDeleting = useSelector(isGroupDeletingSelector);
  const isGroupLeaving = useSelector(isGroupLeavingSelector);
  const isGroupAssigning = useSelector(isGroupToProjectAssigningSelector);
  const isGroupRemoving = useSelector(isGroupFromProjectRemovingSelector);
  const isGroupOperationInProgress =
    isGroupAssigning ||
    isGroupRemoving ||
    isGroupUpdating ||
    isGroupDeleting ||
    isGroupLeaving;
  const previousIsUserRemoving = usePrevious(isUserRemoving);
  const previousIsGroupOperationInProgress = usePrevious(
    isGroupOperationInProgress
  );
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.ADD_USER, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const isUserAdmin = [ROLES.OWNER, ROLES.ADMIN].includes(
    organization?.role as ROLES
  );

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleEditGroupButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_GROUP,
      isOpened: true
    });
  }, []);

  const handleDeleteGroupButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_GROUP,
      isOpened: true
    });
  }, []);

  const handleLeaveGroupButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.LEAVE_GROUP,
      isOpened: true
    });
  }, []);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Groups",
      url: generatePath(ROUTES.GROUPS, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: group?.name || "",
      url: generatePath(ROUTES.GROUP, {
        organizationId: matchParams.organizationId,
        groupId: matchParams.groupId
      })
    }
  ];

  const handleAddUserButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ADD_USER,
      isOpened: true
    });
  }, []);

  const handleRemoveUserMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.REMOVE_USER,
      isOpened: true
    });
  }, []);

  const handleResendInvitationMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.RESEND_INVITATION,
      isOpened: true
    });
  }, []);

  const handleDeleteInvitationMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_INVITATION,
      isOpened: true
    });
  }, []);

  const handleAssignGroupButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ASSIGN_GROUP,
      isOpened: true
    });
  }, []);

  const handleRemoveGroupMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.REMOVE_GROUP,
      isOpened: true
    });
  }, []);

  const userTableActions: TableRowActionsMenuItem<TableUser>[] = [
    {
      label: "Remove",
      isDisabled: (user) => Boolean(user.invitationId),
      handler: handleRemoveUserMenuItemClick
    },
    {
      label: "Resend invitation",
      isDisabled: (user) => !user.invitationId,
      handler: handleResendInvitationMenuItemClick
    },
    {
      label: "Delete invitation",
      isDisabled: (user) => !user.invitationId,
      handler: handleDeleteInvitationMenuItemClick
    }
  ];

  const projectTableActions: TableRowActionsMenuItem<TableProject>[] = [
    {
      label: "Remove",
      handler: handleRemoveGroupMenuItemClick,
      isDisabled: () => !isUserAdmin
    }
  ];

  const generateProjectTableItemURL = useCallback(
    (id: string) => {
      if (group) {
        return generatePath(ROUTES.PROJECT, {
          organizationId: matchParams.organizationId,
          regionId: getProjectRegion(group?.projects, id),
          projectId: id
        });
      }
    },
    [matchParams.organizationId, group]
  );

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.group}`,
        action: enterprisesActions.getGroup.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    if (isUserAdmin) {
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.groupUsers}`,
          action: enterprisesActions.getGroupUsers.started({
            organizationId: matchParams.organizationId!,
            groupId: matchParams.groupId!
          })
        })
      );
    }
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.groupInvitations}`,
        action: enterprisesActions.getGroupInvitations.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organizationProjects}`,
        action: projectsActions.getOrganizationProjects.started({
          organizationId: matchParams.organizationId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
  });

  useEffect(() => {
    if (previousIsUserRemoving && !isUserRemoving) {
      dispatch(
        enterprisesActions.getGroupUsers.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!
        })
      );
    }
  }, [
    previousIsUserRemoving,
    isUserRemoving,
    dispatch,
    matchParams.organizationId,
    matchParams.groupId
  ]);

  useEffect(() => {
    if (
      previousIsInvitationOperationInProgress &&
      !isInvitationOperationInProgress
    ) {
      dispatch(
        enterprisesActions.getGroupInvitations.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!
        })
      );
    }
  }, [
    previousIsInvitationOperationInProgress,
    isInvitationOperationInProgress,
    dispatch,
    matchParams.organizationId,
    matchParams.groupId
  ]);

  useEffect(() => {
    if (previousIsGroupOperationInProgress && !isGroupOperationInProgress) {
      if (!group) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          enterprisesActions.getGroup.started({
            organizationId: matchParams.organizationId!,
            groupId: matchParams.groupId!
          })
        );
      }
    }
  }, [
    group,
    history,
    previousIsGroupOperationInProgress,
    isGroupOperationInProgress,
    dispatch,
    matchParams.organizationId,
    matchParams.groupId
  ]);

  const handleConfirmAddUser = useCallback(
    (data: { email: string }) => {
      dispatch(
        enterprisesActions.inviteUserToGroup.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!,
          email: data.email
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.organizationId,
      matchParams.groupId,
      handleCloseDialog
    ]
  );

  const handleConfirmRemoveUser = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.removeUserFromGroup.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!,
          userId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    dispatch,
    matchParams.organizationId,
    matchParams.groupId,
    handleCloseDialog
  ]);

  const handleConfirmResendInvitation = useCallback(() => {
    if (selectedItemId) {
      const userToResend = users?.find((user) => user.id === selectedItemId);
      if (userToResend) {
        dispatch(
          enterprisesActions.inviteUserToGroup.started({
            organizationId: matchParams.organizationId!,
            groupId: matchParams.groupId!,
            email: userToResend.email
          })
        );
      }
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    dispatch,
    matchParams.organizationId,
    matchParams.groupId,
    handleCloseDialog,
    users
  ]);

  const handleConfirmDeleteInvitation = useCallback(() => {
    if (selectedItemId) {
      const userToResend = users?.find((user) => user.id === selectedItemId);
      if (userToResend?.invitationId) {
        dispatch(
          enterprisesActions.deleteGroupInvitation.started({
            organizationId: matchParams.organizationId!,
            groupId: matchParams.groupId!,
            invitationId: userToResend.invitationId
          })
        );
      }
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    dispatch,
    matchParams.organizationId,
    matchParams.groupId,
    handleCloseDialog,
    users
  ]);

  const handleConfirmAssignGroup = useCallback(
    (data: { project: SelectOption }) => {
      if (organizationProjects) {
        const regionId = getProjectRegion(
          organizationProjects,
          data.project.value
        );
        if (regionId) {
          dispatch(
            projectsActions.assignGroupToProject.started({
              projectId: data.project.value,
              regionId,
              organizationId: matchParams.organizationId!,
              groupId: matchParams.groupId!
            })
          );
        }
      }
      handleCloseDialog();
    },
    [
      dispatch,
      organizationProjects,
      matchParams.organizationId,
      matchParams.groupId,
      handleCloseDialog
    ]
  );

  const handleConfirmRemoveGroup = useCallback(() => {
    if (group && selectedItemId) {
      const regionId = getProjectRegion(group.projects, selectedItemId);
      if (regionId) {
        dispatch(
          projectsActions.removeGroupFromProject.started({
            projectId: selectedItemId,
            regionId,
            organizationId: matchParams.organizationId!,
            groupId: matchParams.groupId!
          })
        );
      }
    }
    handleCloseDialog();
  }, [
    dispatch,
    group,
    selectedItemId,
    matchParams.organizationId,
    matchParams.groupId,
    handleCloseDialog
  ]);

  const handleConfirmLeaveGroup = useCallback(() => {
    dispatch(
      enterprisesActions.leaveGroup.started({
        organizationId: matchParams.organizationId!,
        id: matchParams.groupId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    handleCloseDialog,
    matchParams.organizationId,
    matchParams.groupId
  ]);

  const handleConfirmEditGroup = useCallback(
    (data: { name: string }) => {
      dispatch(
        enterprisesActions.updateGroup.started({
          organizationId: matchParams.organizationId!,
          id: matchParams.groupId!,
          data
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.groupId
    ]
  );

  const handleConfirmDeleteGroup = useCallback(() => {
    dispatch(
      enterprisesActions.deleteGroup.started({
        organizationId: matchParams.organizationId!,
        id: matchParams.groupId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    handleCloseDialog,
    matchParams.organizationId,
    matchParams.groupId
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentProjectName = group?.projects.find(
    (groupProject) => groupProject.id === currentItemId
  )?.name;
  const currentUserName = users?.find((user) => user.id === currentItemId)
    ?.email;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.ADD_USER]: {
      onConfirm: handleConfirmAddUser,
      title: "Add user",
      confirmButtonLabel: "Send invitation",
      fields: [
        {
          name: "email",
          type: FIELD_TYPES.TEXT,
          label: "E-mail address",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_USER]: {
      onConfirm: handleConfirmRemoveUser,
      title: `Are you sure you want to remove "${
        currentUserName ?? "selected user"
      }" from the group?`,
      confirmButtonLabel: "Remove"
    },
    [DIALOG_TYPES.RESEND_INVITATION]: {
      onConfirm: handleConfirmResendInvitation,
      title: `Are you sure you want to resend invitation for "${
        currentUserName ?? "selected user"
      }"?`,
      confirmButtonLabel: "Resend"
    },
    [DIALOG_TYPES.DELETE_INVITATION]: {
      onConfirm: handleConfirmDeleteInvitation,
      title: `Are you sure you want to delete invitation for "${
        currentUserName ?? "selected user"
      }"?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.ASSIGN_GROUP]: {
      onConfirm: handleConfirmAssignGroup,
      title: "Assign to the project",
      confirmButtonLabel: "Assign",
      fields: [
        {
          name: "project",
          type: FIELD_TYPES.SELECT,
          label: "Project",
          options: organizationProjects
            ?.filter(
              (organizationProject) =>
                !group?.projects
                  .map((groupProject) => groupProject.id)
                  .includes(organizationProject.id)
            )
            .map((project) => getSelectOption(project, "name", "id")),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_GROUP]: {
      onConfirm: handleConfirmRemoveGroup,
      title: `Are you sure you want to remove "${
        currentProjectName ?? "selected"
      }" project from the group?`,
      confirmButtonLabel: "Remove"
    },
    [DIALOG_TYPES.LEAVE_GROUP]: {
      onConfirm: handleConfirmLeaveGroup,
      title: `Are you sure you want to leave "${
        group?.name ?? "selected"
      }"  group?`,
      confirmButtonLabel: "Leave"
    },
    [DIALOG_TYPES.EDIT_GROUP]: {
      onConfirm: handleConfirmEditGroup,
      title: "Edit group",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: group?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.GROUP_NAME, ERROR_MESSAGES.GROUP_NAME)
        }
      ]
    },
    [DIALOG_TYPES.DELETE_GROUP]: {
      onConfirm: handleConfirmDeleteGroup,
      title: `Are you sure you want to delete "${
        group?.name ?? "selected"
      }" group?`,
      confirmButtonLabel: "Delete"
    }
  };

  const title = group?.name;

  return (
    <>
      <Head title={title} />
      {group ? (
        <>
          {organization && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              <s.SummaryRow>
                <s.DetailsTitle>ID: </s.DetailsTitle>
                <s.DetailsInfoColored>{group.id}</s.DetailsInfoColored>
              </s.SummaryRow>
            </s.SummaryColumn>
            <s.ActionsContainer>
              <Tooltip title={"Edit"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditGroupButtonClick}
                    disabled={!isUserAdmin}
                    color={"inherit"}
                    // title={"Edit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteGroupButtonClick}
                    disabled={!isUserAdmin}
                    color={"inherit"}
                    // title={"Delete"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Leave"} arrow>
                <span>
                  <IconButton
                    onClick={handleLeaveGroupButtonClick}
                    disabled={isUserAdmin}
                    color={"inherit"}
                    // title={"Leave"}
                  >
                    <LeaveIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </s.ActionsContainer>
          </s.SummaryContainer>
          {isUserAdmin && (
            <Table<TableUser>
              isSearchEnabled={true}
              isSortingEnabled={true}
              rows={users || []}
              columns={userTableColumns}
              actions={userTableActions}
              toolbarItems={
                <Button
                  onClick={handleAddUserButtonClick}
                  variant={"contained"}
                >
                  Add user
                </Button>
              }
              isLoading={!users}
            />
          )}
          {isUserAdmin && (
            <s.Description>
              The users above have access to the following projects
            </s.Description>
          )}
          <Table<TableProject>
            isSearchEnabled={true}
            isSortingEnabled={true}
            rows={group?.projects || []}
            columns={projectTableColumns}
            actions={projectTableActions}
            itemLink={{
              column: "name",
              getURL: generateProjectTableItemURL
            }}
            toolbarItems={
              isUserAdmin && (
                <Button
                  onClick={handleAssignGroupButtonClick}
                  variant={"contained"}
                  disabled={!organizationProjects}
                >
                  Assign to the project
                </Button>
              )
            }
            isLoading={!group}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
